<!--
 * @Author: your name
 * @Date: 2022-03-14 10:48:15
 * @LastEditTime: 2022-05-07 19:00:17
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\pages\links\links.vue
-->
<template>
  <div class="links">正在做...</div>
</template>

<script>
import { mapMutations } from "vuex";
import { onActivated } from "vue";

import useLoading from "../../hooks/useLoading.js";
export default {
  name: "links",
  setup() {
    useLoading();
    //  开启loaing
    onActivated(() => {
      window.scrollTo(0, 0);
    });
    //  关闭loaing
    return {
      ...mapMutations("headInfo", ["UP_ACTIVE_LINK"]),
    };
  },
  activated() {
    this.UP_ACTIVE_LINK("友邻");
  },
  created() {
    this.UP_ACTIVE_LINK("友邻");
  },
};
</script>

<style>
.links {
  width: 40%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  background-color: var(--white_divBgc);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--white_divFc);
  min-width: 600px;
}

@media screen and (max-width: 768px) {
  .links {
    width: 100vw;
    overflow: hidden;
    margin: 0 auto;
    height: 100vh;
    background-color: var(--white_divBgc);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: var(--white_divFc);
    min-width: 300px;
  }
}
</style>
